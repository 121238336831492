import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { isMobile, MobileBreakpoint } from "@/js/util/mobile";
import Alpine from "alpinejs";
import { getColorPalette } from "@/js/util/colorPalette";
import Swiper from "swiper";
import "swiper/css";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(SplitText);

ScrollTrigger.defaults({
  markers: false
});
let introTimeline: GSAPTimeline = null;
let footerTimeline: GSAPTimeline = null;
let serviceTrigger: ScrollTrigger = null;

const initAnimations = function() {
  if (introTimeline || footerTimeline) {
    console.log("Killing timelines");

    introTimeline.kill();
    footerTimeline.kill();
    serviceTrigger.kill();

    console.log("Removing animation styles");
    document.querySelectorAll(".animated").forEach((el) => {
      el.removeAttribute("style");
    });
  }

  // Timeline
  introTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: "#site-header",
      start: "top top",
      end: () => "+=" + window.innerHeight / 3,
      scrub: true
    }
  });

  /****************************
   * Hero Animations
   ***************************/

// Header Gradients
  introTimeline.to("#lightBlueGradient, #darkBlueGradient, #lightBlueLine, #darkBlueLine", {
    opacity: 0,
    duration: 1
  }, 0);

// Header components
//   introTimeline.to("#hamburger, #themeSelector, .header-logo", {
//     color: getColorPalette().header.iconColor
//   }, 0);

// Intro title + text
  introTimeline.to("#intro h1, #intro p", {
    duration: 1,
    color: getColorPalette().pages.home.intro.textColor
  }, 0);

// Intro button/cta
  introTimeline.to("#intro a", {
    backgroundColor: getColorPalette().pages.home.intro.buttonBg,
    color: getColorPalette().pages.home.intro.buttonColor,
    duration: 1
  }, 0);

  /****************************
   * Service Animations
   ***************************/
// Separator
  introTimeline.fromTo(".section-separator", {
      borderColor: getColorPalette().pages.home.services.separatorFromColor,
      color: getColorPalette().pages.home.services.separatorFromColor,
      duration: 1
    },
    {
      borderColor: getColorPalette().pages.home.services.separatorToColor,
      color: getColorPalette().pages.home.services.separatorToColor,
      duration: 1
    }, 0);

  introTimeline.from(".service.is-first", {
    backgroundColor: "transparent",
    borderStyle: "dashed",
    borderColor: "white",
    color: "white",
    duration: 1,
    onComplete: function() {
      const target = this.targets()[0];
      target.style.backgroundColor = "";
      target.style.borderStyle = "";
      target.style.borderColor = "";
      target.style.color = "";
    },
    onStart: function() {
      Alpine.store("services").selected = 0;
    }
  }, 0);

// Only execute the following code if the screen is not mobile
  if (!isMobile(MobileBreakpoint.homeService)) {
    const services = document.querySelectorAll(".service");
    services.forEach((service, index) => {
      service.addEventListener("click", () => {
        Alpine.store("services").animateService(index);
      });
    });

    // Calculate some shenanigans for the scroll trigger start. (we want the services to animate in the center)
    const servicesHeight = document.querySelector("#services").clientHeight;
    const screenHeight = window.innerHeight;
    const calculatedTop = (screenHeight - servicesHeight) / 2;

    serviceTrigger = ScrollTrigger.create({
      trigger: "#services",
      start: `-=${calculatedTop}`,
      end: () => innerHeight * 3,
      pin: true,
      scrub: true,
      onUpdate: (self) => {
        let progress = parseFloat(self.progress.toFixed(2));
        const servicesStore = Alpine.store("services");

        // Trigger our initial animation
        if (progress <= 0 && progress < 0.33 && servicesStore.selected === 0) {
          const serviceHeroContent = document.querySelector(".service-hero-content");
          const splitText = new SplitText(serviceHeroContent, { type: "words, chars" });
          gsap.to(splitText.words, {
            color: getColorPalette().pages.home.services.serviceHeroTextColor,
            stagger: 0.03
          });
        } else if (progress <= 0.33 && servicesStore.selected !== 0) {
          // Animate the first service when scrolling back.
          Alpine.store("services").animateService(0);
        } else if (progress > 0.33 && progress <= 0.66 && servicesStore.selected !== 1) {
          // Animate the second service
          Alpine.store("services").animateService(1);
        } else if (progress > 0.66 && servicesStore.selected !== 2) {
          // Animate the third service
          Alpine.store("services").animateService(2);
        }
      }
    });

    // Store it in the store, so we can manipulate it.
    Alpine.store("services").scrollTrigger = serviceTrigger;
  }

// Footer animations
  footerTimeline = gsap.timeline({
    scrollTrigger: {
      trigger: "#footer",
      start: "-=" + window.innerHeight / 2,
      end: "-=" + window.innerHeight / 4,
      scrub: true
    }
  });

  footerTimeline.to("#lightBlueGradient, #darkBlueGradient, #lightBlueLine, #darkBlueLine", {
    immediateRender: false,
    opacity: 1,
    duration: 2
  }, 0);

  footerTimeline.to("#footer", {
    borderColor: getColorPalette().footer.separatorColor
  }, 0);

  footerTimeline.to("#footer .logo path", {
    fill: getColorPalette().footer.logoColor
  }, 0);

  footerTimeline.to("a.more-insights, #footer .contact-info, #footer h1", {
    color: getColorPalette().footer.textColor
  }, 0);

  footerTimeline.to("#footer .a-button", {
    color: getColorPalette().footer.ctaColor,
    backgroundColor: getColorPalette().footer.ctaBg
  }, 0);
};

// Use a watcher for the changes.
Alpine.effect(() => {
  initAnimations();
});


// Case swiper
new Swiper("#cases-container", {
  direction: "horizontal",
  loop: true,
  spaceBetween: 20,
  slidesPerView: 1.1,
  breakpoints: {
    480: {
      slidesPerView: 1.4
    },
    640: {
      slidesPerView: 1.6
    },
    768: {
      slidesPerView: 2.2
    },
    1024: {
      slidesPerView: 3,
      loop: false
    }
  }
});

